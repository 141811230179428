import clsx from 'clsx';
import { TASK_GROUP_MAPPING } from 'constants/employee/task';
import { TTaskStatusGroup } from 'models/employee/task.model';
import { useMemo } from 'react';

import { Typography } from '@mui/material';

type Props = {
  taskGroup: TTaskStatusGroup;
  total: number;
};

function TaskSidebarSectionHeader({ taskGroup, total }: Props) {
  const header = TASK_GROUP_MAPPING[taskGroup] || '';

  const taskHeaderBgColor = useMemo(() => {
    if (taskGroup === 'todo') return 'bg-todo';
    if (taskGroup === 'onDemand') return 'bg-taskOnDemand';
    if (taskGroup === 'overdue') return 'bg-taskOverdue';
    if (taskGroup === 'inProgress') return 'bg-taskInProgress';
    if (taskGroup === 'pending') return 'bg-taskPending';
    if (taskGroup === 'upcoming') return 'bg-gray-100';
    if (taskGroup === 'audit') return 'bg-tertiary-light-2';
    return 'bg-white';
  }, [taskGroup]);

  const taskNameColor = useMemo(() => {
    if (taskGroup === 'pending') return 'text-white';
    if (taskGroup === 'upcoming') return 'text-taskUpcoming';
    return 'text-secondaryMain';
  }, [taskGroup]);

  return (
    <div className={clsx('sticky top-0 z-10 h-36 bg-white')}>
      <div
        className={clsx(
          'w-full h-full px-16 rounded-t-8 border-gray-200 border-1 flex items-center',
          taskHeaderBgColor,
        )}
      >
        <Typography className={clsx('mr-4 text-13 font-600', taskNameColor)}>{header}</Typography>
        <Typography className={clsx('text-13 font-400', taskNameColor)}>({total})</Typography>
      </div>
    </div>
  );
}

export default TaskSidebarSectionHeader;
