import TextInput from 'app/components/cores/inputs/text';
import clsx from 'clsx';
import { ITEM_TYPES_MAP } from 'constants/task';
import _ from 'lodash';
import { useEffect } from 'react';
import { Control, useController, useFormState, useWatch } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { Typography } from '@mui/material';

type Props = {
  name: string;
  control?: Control;
};

function CommonScoreSetting({ name, control }: Props) {
  const scoreSettingsName = `${name}.scoreSettings`;
  const scoreEnabledName = `${name}.scoreEnabled`;
  const scoreEnabled = useWatch({ name: scoreEnabledName, control });
  const taskType = useWatch({ name: `${name}.type`, control });

  // apply for: Area, Barcode, Employee, Media, QR Code, Salt Ops Form, Signature, Text
  const SCORE_CALCULATE_BY_COMPLETION = [
    ITEM_TYPES_MAP.AREA,
    ITEM_TYPES_MAP.BAR_CODE,
    ITEM_TYPES_MAP.EMPLOYEE,
    ITEM_TYPES_MAP.MEDIA,
    ITEM_TYPES_MAP.QR_CODE,
    ITEM_TYPES_MAP.SALT_OPS_FORM,
    ITEM_TYPES_MAP.SIGNATURE,
    ITEM_TYPES_MAP.TEXT,
  ].includes(taskType);

  const { errors } = useFormState({ control });

  const generateScoreText = () => {
    if (SCORE_CALCULATE_BY_COMPLETION) {
      return 'Score at Completion';
    }
    return 'Score';
  };

  const {
    field: { value: scoreSettings, onChange: onChangeScoreSettings },
  } = useController({
    name: scoreSettingsName,
    control,
    defaultValue: [{ logic: 'completed', score: 0 }],
  });

  const completedSettingIndex = _.findIndex(scoreSettings, { logic: 'completed' });
  const completedSetting = scoreSettings?.[completedSettingIndex];

  // add default zero score for old data
  useEffect(() => {
    if (!scoreSettings?.length || completedSettingIndex > -1) return;
    const nextScoreSettings = _.cloneDeep(scoreSettings);
    onChangeScoreSettings([{ logic: 'completed', score: 0 }, ...nextScoreSettings]);
  }, [scoreSettings, completedSettingIndex, onChangeScoreSettings]);

  const handleChange = (event: any) => {
    if (!/^\d*$/.test(event.target.value)) return;
    const nextScore = Number(event.target.value);
    const nextScoreSettings = _.cloneDeep(scoreSettings);

    if (_.isEmpty(nextScoreSettings)) {
      onChangeScoreSettings([{ logic: 'completed', score: nextScore ?? 0 }]);
      return;
    }

    const setting = _.find(nextScoreSettings, { logic: 'completed' });
    if (!setting) {
      onChangeScoreSettings([{ logic: 'completed', score: nextScore ?? 0 }, ...nextScoreSettings]);
      return;
    }

    onChangeScoreSettings(
      _.map(nextScoreSettings, scoreSetting => {
        if (scoreSetting.logic === 'completed') {
          scoreSetting.score = nextScore ?? 0;
        }
        return scoreSetting;
      }),
    );
  };

  return (
    <div>
      <div
        className={clsx(
          'flex flex-row items-center justify-between space-x-8 mt-4',
          !scoreEnabled && 'cursor-not-allowed',
        )}
      >
        <Typography className="flex-1 text-12 font-400 text-secondaryLight">{generateScoreText()}</Typography>
        <div className={clsx('w-104', !scoreEnabled && 'pointer-events-none')}>
          <TextInput
            placeholder="Enter score"
            className={clsx('w-full px-8 duration-200 rounded-8', scoreEnabled ? 'bg-paper' : 'bg-gray-200')}
            variant="standard"
            InputProps={{ className: 'min-h-32 text-11 text-secondaryLight font-500', disableUnderline: true }}
            value={completedSetting?.score ?? ''}
            onChange={handleChange}
          />
          <ErrorMessage
            name={`${scoreSettingsName}.${completedSettingIndex}.score`}
            errors={errors}
            render={({ message }: any) => <Typography className="text-11 text-errorMain">{message}</Typography>}
          />
        </div>
      </div>
    </div>
  );
}

export default CommonScoreSetting;
